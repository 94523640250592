import { Box, HStack, Img, Text, VStack } from "@chakra-ui/react"
import { DefaultTemplate } from "../../../templates/DefaultTemplate"
import { AboutUsHero } from "../components/AboutUsHero"

export const AboutUsPage = () => {
    return (
        <DefaultTemplate activeNav="ABOUT_US" hero={<AboutUsHero />}>
            <VStack spacing={'80px'}>
                <VStack spacing={'16px'}>
                    <Text fontSize={'16px'} fontWeight={600}>Our Core Values</Text>
                    <Text fontSize={'48px'} fontWeight={600}>Identity & guiding principles of Novatoons.</Text>
                </VStack>
                <VStack spacing={'2px'}>
                    <HStack spacing={'32px'} align={'start'}>
                        <VStack borderRadius={'8px'} bg={'#262333'} align={'start'} w={'400px'} p={'24px'} spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>Creativity Overflowing</Text>
                            <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>Here at Novatoons, we ignite a world of endless imagination to revolutionize the comic storytelling scene. We cheer on our creators to play, experiment, and break the mold, turning fresh, unique ideas into dazzling comics that pop off the page. </Text>
                        </VStack>
                        <VStack spacing={'16px'} justify={'start'}>
                            <Img w={'48px'} h={'48px'} src="/assets/images/logo-notext.png" />
                            <Box w={'3px'} h={'120px'} bg={'#262333'} />
                        </VStack>
                        <Box w={'400px'} />
                    </HStack>
                    <HStack spacing={'32px'} align={'start'}>
                        <Box w={'400px'} />
                        <VStack spacing={'16px'} justify={'start'}>
                            <Img w={'48px'} h={'48px'} src="/assets/images/logo-notext.png" />
                            <Box w={'3px'} h={'120px'} bg={'#262333'} />
                        </VStack>
                        <VStack borderRadius={'8px'} bg={'#262333'} align={'start'} w={'400px'} p={'24px'} spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>Opportunity Without Borders:</Text>
                            <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>We throw open the doors for voices that often go unheard, particularly in mainstream African comics and BIPOC narratives. Novatoons is all about knocking down barriers for emerging artists of color, providing a stage where their talents can shine brightly for a global audience. </Text>
                        </VStack>
                    </HStack>
                    <HStack spacing={'32px'} align={'start'}>
                        <VStack borderRadius={'8px'} bg={'#262333'} align={'start'} w={'400px'} p={'24px'} spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>Respect</Text>
                            <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>At Novatoons, respect is non-negotiable. We celebrate each member of our community, recognizing the rich diversity they bring to our collective comic creation journey. We strive to create an inclusive environment where all interactions are grounded in dignity. </Text>
                        </VStack>
                        <VStack spacing={'16px'} justify={'start'}>
                            <Img w={'48px'} h={'48px'} src="/assets/images/logo-notext.png" />
                            <Box w={'3px'} h={'120px'} bg={'#262333'} />
                        </VStack>
                        <Box w={'400px'} />
                    </HStack>
                    <HStack spacing={'32px'} align={'start'}>
                        <Box w={'400px'} />
                        <VStack spacing={'16px'} justify={'start'}>
                            <Img w={'48px'} h={'48px'} src="/assets/images/logo-notext.png" />
                            <Box w={'3px'} h={'100px'} bg={'#262333'} />
                        </VStack>
                        <VStack borderRadius={'8px'} bg={'#262333'} align={'start'} w={'400px'} p={'24px'} spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>Integrity</Text>
                            <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>Integrity is at the heart of everything we do. We are committed to fairness, transparency, and ethical conduct in all our dealings—whether it's with comic creators, fans, or partners. </Text>
                        </VStack>
                    </HStack>
                    <HStack spacing={'32px'} align={'start'}>
                        <VStack borderRadius={'8px'} bg={'#262333'} align={'start'} w={'400px'} p={'24px'} spacing={'16px'}>
                            <Text fontSize={'20px'} fontWeight={600}>Community-Driven</Text>
                            <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>Community is our cornerstone. Novatoons focuses intensely on nurturing our community's growth and responding to their needs through active engagement and continuous feedback. Our goal is to create a thriving hub where everyone's collective success paints a brighter future for comics. </Text>
                        </VStack>
                        <VStack spacing={'16px'} justify={'start'}>
                            <Img w={'48px'} h={'48px'} src="/assets/images/logo-notext.png" />
                            <Box w={'3px'} h={'120px'} bg={'#262333'} />
                        </VStack>
                        <Box w={'400px'} />
                    </HStack>
                </VStack>

                <HStack w={'full'} justify={'center'} spacing={'100px'}>
                    <VStack borderRadius={'8px'} bg={'#262333'} align={'start'} w={'400px'} p={'24px'} spacing={'16px'}>
                        <Img w={'48px'} h={'48px'} src="/assets/images/logo-notext.png" />
                        <Text fontSize={'20px'} fontWeight={600}>Email</Text>
                        <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in ero.</Text>
                        <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>hello@novatoons.com</Text>
                    </VStack>
                    <VStack borderRadius={'8px'} bg={'#262333'} align={'start'} w={'400px'} p={'24px'} spacing={'16px'}>
                        <Img w={'48px'} h={'48px'} src="/assets/images/logo-notext.png" />
                        <Text fontSize={'20px'} fontWeight={600}>Phone</Text>
                        <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in ero.</Text>
                        <Text color={'gray.300'} fontSize={'16px'} fontWeight={500}>+1 (555) 000-0000</Text>
                    </VStack>
                </HStack>
            </VStack>
        </DefaultTemplate>
    )
}