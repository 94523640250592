import {
  ChakraProvider
} from "@chakra-ui/react"
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect } from "react"
import ReactGA from "react-ga4"
import { Route, Routes } from "react-router-dom"
import { Bootstrap } from "./Bootstrap"
import { Theme } from "./Theme"
import { CartPage } from "./modules/account/pages/CartPage"
import { ChangePasswordPage } from "./modules/account/pages/ChangePasswordPage"
import { ChangePasswordSuccessPage } from "./modules/account/pages/ChangePasswordSuccessPage"
import { ContentListingPage } from "./modules/account/pages/ContentListingPage"
import { CreatePayoutAccountSuccess } from "./modules/account/pages/CreatePayoutAccountSuccess"
import { PayoutPage } from "./modules/account/pages/PayoutPage"
import { SavePreferencePage } from "./modules/account/pages/SavePreferencePage"
import { SettingsPage } from "./modules/account/pages/SettingsPage"
import { ViewContentPage as ViewAccountContentPage } from "./modules/account/pages/ViewContentPage"
import { WalletPage } from "./modules/account/pages/WalletPage"
import { ReadContentPage } from "./modules/contents/pages/ReadContentPage"
import { SaveContentPage } from "./modules/contents/pages/SaveContentPage"
import { SaveContentSuccessPage } from "./modules/contents/pages/SaveContentSuccessPage"
import { SaveReaderSettingsPage } from "./modules/contents/pages/SaveReaderSettingsPage"
import { UploadFilesPage } from "./modules/contents/pages/UploadFilesPage"
import { ViewContentPage } from "./modules/contents/pages/ViewContentPage"
import { AboutUsPage } from "./modules/public/pages/AboutUsPage"
import { CategoryPage } from "./modules/public/pages/CategoryPage"
import { EmailVerificationPage } from "./modules/public/pages/EmailVerificationPage"
import { ExplorePage } from "./modules/public/pages/ExplorePage"
import { ForgotPasswordPage } from "./modules/public/pages/ForgotPasswordPage"
import { GenrePage } from "./modules/public/pages/GenrePage"
import { LoginPage } from "./modules/public/pages/LoginPage"
import { RegisterByEmailPage } from "./modules/public/pages/RegisterByEmailPage"
import { RegisterPage } from "./modules/public/pages/RegisterPage"
import { RegisterSuccessPage } from "./modules/public/pages/RegisterSuccessPage"
import { UserProvider } from "./providers/UserProvider"
import { PrivacyPage } from "./modules/public/pages/PrivacyPage"
ReactGA.initialize("G-RSFD578P1Y");

export const App = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string)

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  return (
    <>
      <StripeElements stripe={stripePromise}>
        <ChakraProvider theme={Theme}>
          <UserProvider>
            <Routes>
              <Route path="/" Component={Bootstrap} />
              <Route path="/explore" Component={ExplorePage} />
              <Route path="/about-us" Component={AboutUsPage} />
              <Route path="/category/:category" Component={CategoryPage} />
              <Route path="/genre" Component={GenrePage} />
              <Route path="/genre/:genre" Component={GenrePage} />
              <Route path="/register" Component={RegisterPage} />
              <Route path="/register/email" Component={RegisterByEmailPage} />
              <Route path="/register/verify/:token" Component={EmailVerificationPage} />
              <Route path="/register/success" Component={RegisterSuccessPage} />
              <Route path="/recover" Component={ForgotPasswordPage} />
              <Route path="/recover/verify/:token" Component={() => <EmailVerificationPage source="RECOVER_ACCOUNT" />} />
              <Route path="/login" Component={LoginPage} />
              <Route path="/contents/save" Component={SaveContentPage} />
              <Route path="/contents/save/:id" Component={SaveContentPage} />
              <Route path="/contents/upload/:id" Component={UploadFilesPage} />
              <Route path="/contents/readers/:id" Component={SaveReaderSettingsPage} />
              <Route path="/contents/success/:id" Component={SaveContentSuccessPage} />
              <Route path="/contents/:id" Component={ViewContentPage} />
              <Route path="/contents/preview/:id" Component={() => <ViewContentPage isPreview={true} />} />
              <Route path="/contents/read/:content_id" Component={ReadContentPage} />
              <Route path="/contents/read/:content_id/:sub_content_id" Component={ReadContentPage} />
              <Route path="/account/contents" Component={ContentListingPage} />
              <Route path="/account/contents/:id" Component={ViewAccountContentPage} />
              <Route path="/account/recover" Component={ChangePasswordPage} />
              <Route path="/account/recover/success" Component={ChangePasswordSuccessPage} />
              <Route path="/account/settings" Component={SettingsPage} />
              <Route path="/account/payout" Component={PayoutPage} />
              <Route path="/account/wallet" Component={WalletPage} />
              <Route path="/account/setup" Component={SavePreferencePage} />
              <Route path="/account/payout/setup/success" Component={CreatePayoutAccountSuccess} />
              <Route path="/account/cart" Component={CartPage} />
              <Route path="/privacy" Component={PrivacyPage} />
            </Routes>
          </UserProvider>
        </ChakraProvider>
      </StripeElements>
    </>


  )
}
