import { Button, HStack, IconButton, Img, Input, Text, VStack } from "@chakra-ui/react"
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

export const Footer = () => {
    const navigate = useNavigate()
    return (
        <VStack w={'full'} pt={'50px'} align={'center'}>
            <VStack w={'full'} bg={'black'} pt={'64px'} pb={'48px'} >
                <VStack w={'1460px'} spacing={'64px'}>
                    <HStack w={'full'} align={'start'} justify={'space-between'}>
                        <VStack align={'start'} w={'320px'} spacing={'32px'}>
                            <Img src={`/assets/images/logo-vertical.png`} h={'64px'} />
                            <Text fontSize={'16px'} fontWeight={400} color={'gray.400'}>
                                Your gateway to a world of imagination and creativity!
                            </Text>
                        </VStack>
                        <HStack spacing={'32px'} align={'start'}>
                            <VStack spacing={'16px'} w={'100px'}>
                                <Text fontSize={'14px'} color={'green.200'}>Company</Text>
                                <VStack spacing={'12px'}>
                                    <Text cursor={'pointer'} onClick={()=>navigate(`/about-us`)}>About us</Text>
                                    <Text>Contact</Text>
                                    <Text>Forums</Text>
                                </VStack>
                            </VStack>
                            <VStack spacing={'16px'} w={'100px'}>
                                <Text fontSize={'14px'} color={'green.200'}>Resources</Text>
                                <VStack spacing={'12px'}>
                                    <Text>Comics</Text>
                                    <Text>Art</Text>
                                    <Text>e-Book</Text>
                                    <Text>Tutorials</Text>
                                    <Text>Pricing</Text>
                                    <Text>Releases</Text>
                                </VStack>
                            </VStack>
                            <VStack spacing={'16px'} w={'100px'}>
                                <Text fontSize={'14px'} color={'green.200'}>Legal</Text>
                                <VStack spacing={'12px'}>
                                    <Text>Terms</Text>
                                    <Text cursor={'pointer'} onClick={()=>navigate(`/privacy`)}>Privacy</Text>
                                    <Text>Cookies</Text>
                                    <Text>Licenses</Text>
                                    <Text>Settings</Text>
                                </VStack>
                            </VStack>
                        </HStack>
                        <VStack w={'410px'} spacing={'32px'}>
                            <VStack w={'full'} spacing={'8px'} align={'start'} >
                                <Text fontSize={'20px'} fontWeight={500}>Join our newsletter</Text>
                                <Text fontSize={'16px'} fontWeight={400} color={'gray.300'}>Get the latest stories—join our newsletter! No spam!</Text>
                            </VStack>
                            <HStack w={'full'}>
                                <Input w={'280px'} bg={'#353444'} variant={'filled'} placeholder={'Enter your email'} />
                                <Button bg={'green.400'} colorScheme={'green'}>Subscribe</Button>
                            </HStack>
                        </VStack>
                    </HStack>
                    <HStack pt={'16px'} w={'full'} justify={'space-between'} borderTopWidth={'1px'} borderTopColor={'green.300'}>
                        <Text fontSize={'16px'} fontWeight={400} color={'gray.300'}>&copy; 2024 Novatoon All rights reserved.</Text>
                        <HStack>
                            <IconButton variant={'ghost'} aria-label={'Twitter'} icon={<FaTwitter />} />
                            <IconButton variant={'ghost'} aria-label={'Twitter'} icon={<FaYoutube />} />
                            <IconButton variant={'ghost'} aria-label={'Twitter'} icon={<FaFacebook />} />
                            <IconButton variant={'ghost'} aria-label={'Twitter'} icon={<FaInstagram />} />
                        </HStack>
                    </HStack>
                </VStack>
            </VStack>
        </VStack>
    )
}