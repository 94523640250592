import { Button, HStack, IconButton, Stack, Text, VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { BsChatSquare, BsInfoCircle } from "react-icons/bs";
import { FiChevronLeft, FiMoreVertical, FiSettings } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { findWhere } from "underscore";
import { ContentDetailsCard } from "../../../components/ContentDetailsCard";
import { ContentProps, Get, GetFreePageListing, GetPageListing, SubContentProps } from "../../../services/ContentService";
import { MainTemplate } from "../../../templates/MainTemplate";
import { ContentCommentBox } from "../components/ContentCommentBox";
import { ContentNotAccessibleDialog } from "../components/ContentNotAccessibleDialog";
import ContentReader from "../components/ContentReader";
import { ReadContentSettings } from "../components/ReadContentSettings";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";
import { UserContext } from "../../../providers/UserProvider";



export const ReadContentPage = () => {
    const [content, setContent] = useState<ContentProps>()
    const { content_id, sub_content_id } = useParams()
    const [is_ready, setIsReady] = useState<boolean>()
    const [is_accessible, setIsAccessible] = useState<boolean>()
    const [active_option, setActiveOption] = useState<'COMMENT' | 'SETTINGS' | 'INFO' | undefined>()
    const [sub_content, setSubContent] = useState<SubContentProps>()
    const [layout, setLayout] = useState<string>('VERTICAL')
    const { isMobile } = useScreenSize()
    const { user } = useContext(UserContext)

    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if (content_id) {
                try{
                    const result = await Get(content_id, true)
                    if (result) {
                        let sub_content = result.sub_contents[0]
                        if (sub_content_id) {
                            sub_content = findWhere(result.sub_contents, { id: parseInt(sub_content_id) })
                        }
    
                        const { id } = sub_content
                        let _result = []
                        if(user){
                            _result = await GetPageListing(id)
                        }else{
                            _result = await GetFreePageListing(id)
                        }
                        
                        sub_content.pages = [..._result.map((item: any) => item.url)]
                        if (sub_content && sub_content.pages && sub_content.pages.length > 0) {
                            setIsAccessible(() => true)
                        }
                        setSubContent(() => sub_content)
                    }
                    setContent(() => result)
                }catch(e){
                    console.log(e, 'e')
                }
                setIsReady(() => true)
            }
        })();
    }, [content_id, sub_content_id]);

    return (
        <MainTemplate>
            {!is_ready &&
                <Text>Loading, please wait...</Text>
            }
            {(is_ready && is_accessible && content && sub_content) &&
                <VStack w={'full'}>
                    <VStack w={'full'} maxW={'1340px'} spacing={'32px'} align={'center'} pos={'relative'}>
                        <HStack w={'full'} justify={'space-between'}>
                            <HStack spacing={'24px'}>
                                <Button onClick={() => navigate(-1)} leftIcon={<FiChevronLeft />}>Back</Button>
                                <Text fontWeight={600} fontSize={'20px'}>{sub_content.title}</Text>
                            </HStack>
                            {!isMobile &&
                                <HStack spacing={'16px'}>
                                    <IconButton onClick={() => setActiveOption('COMMENT')} aria-label={'Comment'} icon={<BsChatSquare />} />
                                    <IconButton onClick={() => setActiveOption('INFO')} aria-label={'Info'} icon={<BsInfoCircle />} />
                                    <IconButton onClick={() => setActiveOption('SETTINGS')} aria-label={'Settings'} icon={<FiSettings />} />
                                    <IconButton aria-label={'More'} icon={<FiMoreVertical />} />
                                </HStack>
                            }

                        </HStack>
                        {(sub_content && sub_content.pages && sub_content.pages.length > 0) &&
                            <ContentReader content={content} isBought={sub_content.is_bought} layout={layout} pages={sub_content.pages} />
                        }
                        <HStack pos={'absolute'} top={'60px'} right={0} spacing={'24px'} align={'start'}>
                            {active_option === 'COMMENT' &&
                                <ContentCommentBox subContent={sub_content as SubContentProps} onClose={() => setActiveOption(undefined)} />
                            }
                            {active_option === 'INFO' &&
                                <ContentDetailsCard hasClose={true} onClose={() => setActiveOption(undefined)} hasHeader={true} width={'440px'} content={content} />
                            }
                            {active_option === 'SETTINGS' &&
                                <ReadContentSettings onClose={() => setActiveOption(undefined)} onChangeView={mode => setLayout(() => mode)} />
                            }
                        </HStack>
                    </VStack>
                </VStack>
            }
            {(is_ready && !is_accessible) &&
                <ContentNotAccessibleDialog />
            }
        </MainTemplate>
    )
}