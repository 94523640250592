import { Box, Button, HStack, Img, Text, VStack } from "@chakra-ui/react";
import { useAnalyticsEventTracker } from "../../../providers/UseAnalyticsEventTracker";
import { Hero } from "./Hero";

export const AboutUsHero = () => {

    const gaEventTracker = useAnalyticsEventTracker('AboutUs');

    const getStarted = () => {
        console.log('Get Started')
        gaEventTracker('Get Started')
    }
    return (
        <VStack spacing={0} w={'full'} pos={'relative'}>
            <Box pos={'absolute'} w={'full'} h={'680px'} bg={'black'} opacity={0.8} />
            <Hero text={'About Us'} bgImage={'/assets/images/backgrounds/about-us-hero.jpg'} height={'680px'}>
                <VStack w={'full'} spacing={'24px'}>
                    <Text w={'600px'} fontSize={'20px'} textAlign={'center'}>
                        Welcome to Novatoons! We're thrilled to have you here, exploring a world of creativity and storytelling through comics.
                    </Text>
                    <HStack spacing={'20px'} w={'full'} justify={'center'}>
                        <VStack p={'24px'} 
                                spacing={'24px'} 
                                align={'start'}
                                w={'400px'} 
                                h={'340px'}
                                pos={'relative'}>
                            <VStack spacing={'16px'} align={'start'}>
                                <Img w={'30px'} h={'30px'} src="/assets/images/logo-notext.png" />
                                <Text fontSize={'20px'} fontWeight={600}>Mission</Text>
                            </VStack>
                            <Text lineHeight={'22px'} fontSize={'16px'} fontWeight={500}>At Novatoons, we're on a mission to illuminate the world with vibrant stories from BIPOC comic creators. We believe in the power of diversity to inspire, educate, and entertain. Our platform is more than just a marketplace; it's a launching pad for underrepresented artists to showcase their unique voices and incredible talents.</Text>
                            <Box left={0} top={0} borderRadius={'8px'}  zIndex={-1} w={'400px'} h={'340px'} backdropBlur={16} bg={'#262333B2'} pos={'absolute'} />
                        </VStack>
                        <VStack p={'24px'} 
                                spacing={'24px'} 
                                align={'start'}
                                w={'400px'} 
                                h={'340px'}
                                pos={'relative'}>
                            <VStack spacing={'16px'} align={'start'}>
                                <Img w={'30px'} h={'30px'} src="/assets/images/logo-notext.png" />
                                <Text fontSize={'20px'} fontWeight={600}>Vision</Text>
                            </VStack>
                            <Text lineHeight={'22px'} fontSize={'16px'} fontWeight={500}>
                            We envision a world where every comic lover has access to a multitude of perspectives, where BIPOC creators are celebrated and supported, and where the next generation of comic fans can see themselves in the stories they read. Novatoons isn't just about comics; it's about changing the narrative, one panel at a time.
                            </Text>
                            <Box left={0} top={0} borderRadius={'8px'}  zIndex={-1} w={'400px'} h={'340px'} backdropBlur={16} bg={'#262333B2'} pos={'absolute'} />
                        </VStack>
                        <VStack p={'24px'} 
                                spacing={'24px'} 
                                align={'start'}
                                w={'400px'} 
                                h={'340px'}
                                pos={'relative'}>
                            <VStack spacing={'16px'} align={'start'}>
                                <Img w={'30px'} h={'30px'} src="/assets/images/logo-notext.png" />
                                <Text fontSize={'20px'} fontWeight={600}>What We're About</Text>
                            </VStack>
                            <Text lineHeight={'22px'} fontSize={'16px'} fontWeight={500}>
                            Novatoons is a bustling online hub where comic enthusiasts can dive into a sea of creativity. Here, you can purchase comics, support your favorite artists through donations, and immerse yourself in worlds crafted by the finest independent talents from across the globe. Whether you're looking to laugh, cry, or find a new hero to root for, our ever-expanding library is packed with fresh and engaging content.
                            </Text>
                            <Box left={0} top={0} borderRadius={'8px'}  zIndex={-1} w={'400px'} h={'340px'} backdropBlur={16} bg={'#262333B2'} pos={'absolute'} />
                        </VStack>
                    </HStack>
                </VStack>
            </Hero>
        </VStack>

    )
}