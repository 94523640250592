import { Box, Text, VStack } from "@chakra-ui/react"
import { DefaultTemplate } from "../../../templates/DefaultTemplate"

export const PrivacyPage = () => {
    return (
        <DefaultTemplate>
            <VStack w={'1200px'} align={'start'} pt={'100px'} spacing={'24px'}>
                <Text fontWeight={600} fontSize={'3xl'}>Privacy Policy</Text>
                <VStack align={'start'} spacing={'16px'}>
                    <Text fontWeight={600} fontSize={'xl'}>Article 1 - Introduction:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Welcome to Novatoons, a platform where creativity meets commerce. At Novatoons Entertainment Inc., we prioritize your privacy and are committed to maintaining the trust and confidence of our users. This Privacy Policy serves as a testament to our dedication to safeguarding your personal information. It outlines how we collect, use, and protect your data, ensuring compliance with major privacy regulations, including the General Data Protection Regulation (GDPR), the Personal Information Protection and Electronic Documents Act (PIPEDA), the California Consumer Privacy Act (CCPA), and other applicable laws. By using our platform, you acknowledge that you have read, understood, and agree to the practices described in this policy.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 2 - Data Collection:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Your interaction with Novatoons allows us to gather various types of data to enhance your experience:
                        Personal Data: This includes information you provide directly, such as your name, email address, and payment details.
                        Non-Personal Data: We collect data related to your platform usage, device information, and browsing habits to improve our services.
                        Methods: Data is primarily collected through registration forms, transaction records, and the use of cookies and similar tracking technologies.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 3 - Data Usage:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Your data is invaluable, not just to you but to us as well. Here's how we utilize the information we collect:
                        Service Provision: To facilitate transactions, communicate with you, and offer personalized recommendations.
                        Platform Improvement: Analyzing user behavior to enhance platform functionality and user experience.
                        Marketing and Promotions: With your consent, we might send newsletters, promotional offers, and other marketing communications.
                        Legal and Compliance: Ensuring adherence to legal obligations and addressing potential policy or terms violations.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 4 - Data Storage and Security:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        At Novatoons Entertainment Inc., the security of your data is paramount:
                        Storage: Your data is stored on secure servers, with backups in place to prevent data loss.
                        Security Measures: We employ state-of-the-art encryption, firewalls, and other security measures to prevent unauthorized access or breaches.
                        Retention: Data is retained only for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required by law.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 5 - Data Sharing and Third Parties:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Your trust is sacred to us, and we're judicious about sharing your data:
                        Third-Party Partners: We collaborate with trusted partners for payment processing, marketing, and other services. They have access only to the data necessary for their specific tasks and are bound by strict confidentiality agreements.
                        Legal Obligations: In certain circumstances, such as legal disputes or regulatory inquiries, we might be compelled to disclose user data.
                        Business Transfers: In the event of a merger, acquisition, or asset sale, user data may be part of the transferred assets. Affected users will be notified in such cases.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 6 - User Rights and Control:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Your rights regarding your data are of utmost importance to us at Novatoons Entertainment Inc.:
                        Access: You have the right to request details of the personal information we hold about you.
                        Correction: If you believe any information we have on you is incorrect or incomplete, you can request to see this information and have it corrected or amended.
                        Deletion: Under certain circumstances, you can request for your personal data to be deleted from our records.
                        Restriction: You may have the right to limit the ways in which we use your personal data.
                        Data Portability: You can request that we move, transfer, or port your personal data to another service or provider where technically feasible.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 7 - Cookies and Tracking Technologies:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        To enhance user experience and gather insights about platform usage, we employ cookies and other tracking technologies:
                        Purpose: Cookies help us remember user preferences, understand platform usage patterns, and deliver personalized content.
                        Control: Users can manage or disable cookies through their browser settings. However, disabling cookies might affect certain platform functionalities.
                        Third-Party Cookies: Some cookies are set by third parties for services like analytics or advertising. We ensure these third parties adhere to strict privacy standards.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 8 - GDPR Compliance:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        For our users within the European Economic Area (EEA), we are dedicated to ensuring compliance with the General Data Protection Regulation (GDPR):
                        Data Subject Rights: EEA users have the right to access, correct, or delete their personal data stored by Novatoons. Additionally, they can object to the processing of their data, request data portability, or restrict processing in certain circumstances.
                        Legal Basis for Processing: We process personal data based on legitimate interests, user consent, contractual necessity, or as required by law. Users have the right to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.
                        Data Protection Officer: We have appointed a Data Protection Officer to oversee our GDPR compliance. Users can contact our Data Protection Officer for any GDPR-related inquiries.
                        Cross-Border Data Transfers: Any transfer of data outside the EEA is done with appropriate safeguards, such as standard contractual clauses approved by the European Commission.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 9 - PIPEDA Compliance:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        For our Canadian users, we adhere to the standards set by the Personal Information Protection and Electronic Documents Act (PIPEDA):
                        Consent: Personal information is only collected, used, or disclosed with the knowledge and consent of the individual, except where otherwise required or permitted by law.
                        Accountability: We are responsible for all personal information under our control and have designated an individual to ensure compliance with PIPEDA principles.
                        Accuracy: Personal information shall be as accurate, complete, and up-to-date as necessary for the purposes for which it is to be used.
                        Safeguards: Security safeguards appropriate to the sensitivity of the information protect all personal data.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 10 - CCPA Compliance:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        For our users in California, we are committed to upholding the standards of the California Consumer Privacy Act (CCPA):
                        Rights of California Residents: California residents have the right to know about the personal information collected, used, shared, or sold. They can also request deletion of their personal information and opt-out of the sale of their personal information.
                        Non-Discrimination: We will not discriminate against users for exercising their CCPA rights. This includes not denying goods or services, charging different prices, or providing a different quality of service based on exercised rights.
                        Notice and Consent: Before collecting personal information, we inform users of the categories of personal information to be collected and the purposes for which they will be used.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 11 - Other US Privacy Laws:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        We recognize the importance of state-specific privacy regulations within the United States and are committed to ensuring compliance:
                        State-Specific Rights: Depending on the state, users may have additional rights concerning their personal data. We are committed to honoring these rights and providing necessary mechanisms for users to exercise them.
                        Updates and Compliance: As state-specific privacy laws evolve, we will update our policy to reflect any new obligations and ensure compliance. We actively monitor developments in privacy regulations across all US states to ensure our practices remain up-to-date.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 12 - International Data Transfers:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Given our global user base, data may be transferred across international borders:
                        Safeguards: We ensure that any international transfer of personal data is done with adequate safeguards in place, adhering to international data protection standards.
                        User Consent: By using our platform, users consent to the transfer of their data across international borders, in compliance with our privacy standards.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 13 - Children's Privacy:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Novatoons is committed to protecting the privacy of children:
                        Age Restrictions: Our platform is not intended for users under the age of 13. We do not knowingly collect or solicit personal information from children.
                        Parental Control: If parents or guardians become aware that their child has provided us with personal information without their consent, they should contact us. We will take steps to remove such information from our records.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 14 - Data Breach Procedures:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        In the unlikely event of a data breach
                        Notification: We commit to notifying affected users and relevant authorities within 72 hours of becoming aware of any breach that poses a risk to user rights and freedoms.
                        Measures: We have procedures in place to address data breaches, including system assessments, user notifications, and corrective actions.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 15 - Third-Party Links:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Our platform may contain links to third-party websites
                        Disclaimer: We are not responsible for the privacy practices or content of third-party sites. Users are encouraged to read the privacy policies of any external sites they visit.
                        User Discretion: Accessing third-party links is at the user's discretion and risk.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 16 - Changes to the Privacy Policy:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        Our Privacy Policy may evolve over time
                        Updates: We reserve the right to update or modify this policy at our discretion. Any changes will be communicated to users through appropriate channels
                        User Responsibility: It's the user's responsibility to review the policy periodically to stay informed about our data practices.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 17 - Consent and Agreement:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        By using our platform, users agree to our data practices:
                        Affirmation: Use of Novatoons signifies the user's consent to the collection, storage, and processing of their data as described in this policy.
                        Withdrawal: Users can withdraw their consent at any time, subject to legal or contractual restrictions.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 18 - Dispute Resolution:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        In case of any disagreements related to data practices:
                        Mediation: We encourage users to contact us directly to resolve any concerns. If issues persist, mediation may be sought before any legal action.
                        Jurisdiction: Any disputes arising from this policy will be resolved under the jurisdiction of Canada or Delaware, US, as applicable.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 19 - Applicable Jurisdiction:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        This Privacy Policy is governed by the laws of Canada and the state of Delaware, US:
                        Legal Framework: All matters related to data protection and privacy will be addressed under the legal framework of the user's jurisdiction or the platform's operating jurisdiction, whichever is applicable.
                    </Text>
                    <Text fontWeight={600} fontSize={'xl'}>Article 20 - Contact Information for Privacy Concerns:</Text>
                    <Text fontSize={'md'} fontWeight={500}>
                        For any queries or concerns related to this Privacy Policy:
                        Dedicated Channel: Users can reach out to our designated data protection officer or privacy team through the contact details provided on our platform.
                        Feedback: We welcome feedback to continually refine our data practices and ensure user trust.
                    </Text>
                </VStack>
            </VStack>
        </DefaultTemplate>
    )
}