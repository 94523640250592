import { Avatar, Button, Center, CenterProps, Grid, GridItem, HStack, IconButton, Img, StackProps, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsStar } from "react-icons/bs";
import { FiDownload, FiMoreVertical, FiThumbsUp } from "react-icons/fi";
import { RiUserLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { pluck } from "underscore";
import { ContentPurchaseSuccessModal } from "../../../components/ContentPurchaseSuccessModal";
import { CreditCardPaymentModal } from "../../../components/CreditCardPaymentModal";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";
import { ContentProps, GetPublicListing, SubContentProps } from "../../../services/ContentService";
import { ContentCard } from "../../public/components/ContentCard";
import moment from "moment";

interface ContentDetailsCardProps extends StackProps {
    data: ContentProps;
}

const tag_style: CenterProps = {
    borderRadius: '8px',
    bg: '#2F2C3B',
    px: '18px',
    py: '12px',
    fontSize: '12px',
    fontWeight: 400,
    cursor: 'pointer',
    textTransform: 'capitalize'
}


export const ContentDetailsCard = ({ data: content, ...rest }: ContentDetailsCardProps) => {
    const navigate = useNavigate()
    const [author_contents, setAuthorContents] = useState<any[]>([])
    const [related_contents, setRelatedContents] = useState<any[]>([])

    const { isMobile } = useScreenSize()

    useEffect(() => {
        console.log(content, 'content');
        (async () => {
            const result = await GetPublicListing({ user_id: content.owner.id })
            setAuthorContents(() => [...result])
        })();
        (async () => {
            const result = await GetPublicListing({ genres: content.genres })
            setRelatedContents(() => [...result.filter((item: any) => item.id !== content.id)])
        })();
    }, [content])

    const viewIssue = (sub_content: SubContentProps) => {
        navigate(`/contents/read/${content.code}/${sub_content.id}`)
    }

    const onPurchase = () => {

    }

    return (
        <VStack spacing={'24px'} padding={'24px'} bg={'#262333'} borderRadius={'8px'} {...rest}>
            <HStack w={'full'} justify={'space-between'} align={'start'}>
                <VStack spacing={0} align={'start'}>
                    <Text fontSize={'24px'} fontWeight={600}>{content.title}</Text>
                </VStack>
                <Center minW={'100px'}
                    fontSize={'14px'}
                    fontWeight={600}
                    color={'gray.200'}
                    borderRadius={'8px'}
                    py={'6px'}
                    px={'16px'}
                    bg={'rgb(255, 255, 255, 0.04)'}>
                    {content.is_paid ? `${content.price} USD` : 'Free'}
                </Center>
            </HStack>
            <HStack justify={'start'} w={'full'} spacing={'8px'}>
                {content.owner.profile_picture ?
                    <Avatar borderRadius={'4px'} src={content.owner.profile_picture} size={'xl'} />
                    :
                    <Center borderRadius={'8px'} borderWidth={'3px'} borderColor={'white'} h={'80px'} w={'80px'}>
                        <RiUserLine size={'32px'} color={'white'} />
                    </Center>
                }
                <VStack align={'start'} spacing={0}>
                    <Text color={'gray.300'}>Author</Text>
                    <Text textDecoration={'underline'} color={'gray.200'} fontWeight={600}>{content.owner.firstname} {content.owner.lastname}</Text>
                </VStack>
            </HStack>
            <HStack w={'full'} justify={'space-between'}>
                <HStack spacing={'12px'}>
                    <HStack spacing={'4px'}>
                        <IconButton aria-label={'Like'} icon={<FiThumbsUp />} />
                        <Text fontWeight={'16px'} color={'gray.200'}>0</Text>
                    </HStack>
                    <HStack spacing={'4px'}>
                        <IconButton aria-label={'Stars'} icon={<BsStar />} />
                        <Text fontWeight={'16px'} color={'gray.200'}>4.5 (120)</Text>
                    </HStack>
                </HStack>
                <HStack spacing={'12px'}>
                    {!isMobile &&
                        <>
                            <Button onClick={() => navigate(`/contents/read/${content.code}`)} size={'sm'} color={'green.400'}>Read Free pages</Button>
                            {(content.is_paid && content.sub_contents && content.sub_contents.length > 0) &&
                                <CreditCardPaymentModal subContentIds={[content.sub_contents[0].id as number]} amount={content.price as number} onSuccess={onPurchase} />
                            }
                            <ContentPurchaseSuccessModal />
                        </>
                    }
                    <Tooltip colorScheme={"black"} label={'Functionality under development'} hasArrow={true}>
                        <IconButton size={'sm'} aria-label={'more'} icon={<FiMoreVertical />} />
                    </Tooltip>
                </HStack>
            </HStack>
            <HStack w={'full'} justify={'start'}>
                <Text fontSize={'12px'} color={'gray.300'}>{content.description}</Text>
            </HStack>
            <VStack w={'full'} align={'start'}>
                <HStack>
                    <Text fontSize={'12px'} color={'gray.400'}>Genres:</Text>
                    <Text fontSize={'12px'} color={'gray.300'}>{pluck(content.genres, 'name').join(',')}</Text>
                </HStack>
                <HStack>
                    <Text fontSize={'12px'} color={'gray.400'}>Tags:</Text>
                    <Text fontSize={'12px'} color={'gray.300'}>{pluck(content.tags, 'name').join(', ')}</Text>
                </HStack>
            </VStack>
            <Tabs pt={isMobile ? '16px' : '32px'} colorScheme={'green'} w={'full'}>
                <TabList className={'hidden-scrollbar'} w={'full'} overflowX={'auto'} overflowY={'hidden'} pb={'2px'}>
                    {content.type === 'SERIES' &&
                        <Tab fontSize={'14px'}>
                            Issues
                        </Tab>
                    }
                    <Tab fontSize={'14px'}>
                        Comments
                    </Tab>
                    <Tab minW={'220px'} fontSize={'14px'}>
                        More comics from this author
                    </Tab>
                    <Tab minW={'130px'} fontSize={'14px'}>
                        Similar comics
                    </Tab>
                </TabList>
                <TabPanels>
                    {(content.type === 'SERIES' && content.sub_contents && content.sub_contents.length > 0) &&
                        <TabPanel px={0}>
                            <VStack align={'start'} spacing={'12px'}>
                                {content.sub_contents.map((item: any, index: number) =>
                                    <HStack key={index} py={'24px'} w={'full'} justify={'space-between'} borderBottomWidth={'1px'} borderBottomColor={'gray.900'}>
                                        <HStack  spacing={'16px'}>
                                            <Img w={'85px'} 
                                                h={'85px'} 
                                                borderRadius={'4px'}
                                                borderWidth={'1px'}
                                                borderColor={'red'}
                                                objectFit={'contain'} src={item.thumbnail_url} />
                                            <VStack justify={'space-between'} align={'start'}>
                                                <VStack align={'start'} spacing={0}>
                                                    <Text fontWeight={'bold'} fontSize={'12px'}>
                                                        {item.title}
                                                    </Text>
                                                    <Text fontSize={'14px'}>
                                                        {item.description}
                                                    </Text>
                                                </VStack>
                                                <Text fontSize={'12px'}>{ moment(item.created_at).format('MM/DD/YYYY') }</Text>
                                            </VStack>
                                        </HStack>
                                        <VStack justify={'space-between'} minH={'85px'}>
                                            <Text fontSize={'12px'} fontWeight={600} cursor={'pointer'} onClick={()=> viewIssue(item)}>Read</Text>
                                            <IconButton isDisabled={!item.is_downloadable} size={'sm'} aria-label="Download" icon={<FiDownload />} />
                                        </VStack>
                                    </HStack>
                                )}
                            </VStack>
                        </TabPanel>
                    }
                    <TabPanel px={0}>
                        <Text fontSize={'12px'}>This feature is coming soon</Text>
                    </TabPanel>
                    <TabPanel px={0}>
                        {author_contents.length > 0 &&
                            <Grid templateColumns={`repeat(${isMobile ? 2 : 3}, 1fr)`} gap={'16px'}>
                                {author_contents.map((item: any, index: number) =>
                                    <GridItem key={index}>
                                        <ContentCard content={item} />
                                    </GridItem>
                                )}
                            </Grid>
                        }
                        {author_contents.length === 0 &&
                            <Text fontSize={'12px'}>
                                {content.owner.firstname} doesn't have any other contents yet
                            </Text>
                        }
                    </TabPanel>
                    <TabPanel px={0}>
                        {related_contents.length > 0 &&
                            <Grid templateColumns={`repeat(${isMobile ? 2 : 3}, 1fr)`} gap={'16px'}>
                                {related_contents.map((item: any, index: number) =>
                                    <GridItem key={index}>
                                        <ContentCard content={item} />
                                    </GridItem>
                                )}
                            </Grid>
                        }
                        {related_contents.length === 0 &&
                            <Text fontSize={'12px'}>No other contents related to this comic</Text>
                        }
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    )
}